import React from 'react';
import Plot from 'react-plotly.js';
import Heading2 from '../Heading/Heading2';
import { apiFetchGraphData } from '../../auth/auth';

class VolumeGraph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      company: props.name,
      index: 0,
      volumeChartXValues: [1, 2, 3, 4, 5, 6],
      volumeChartXLabels: [1, 2, 3, 4, 5, 6],
      volumeChartYValues: [],
      min: 0,
    }
  }


  fetchData = async () => {
    const response = await apiFetchGraphData();
    if (response === undefined) {
      console.log("Error");
    }
    else {
      if (response.status >=200 && response.status<=299) {
        const res = response.data;
        if (res) {
          // console.log(res);
          const vData = res.vData;
          this.setState(
            {
              // index: res.i,
              volumeChartXLabels: vData['Time'],
              volumeChartYValues: vData[this.state.company],
              min: res.time
            },
          );
          // this.loadData();
        }
      }
      else if (response.status >= 400 && response.status < 500) {
        //about to fill
      }
      else if (response.status >= 500 && response.status < 600) {
        console.log("Server Side Error");
      }
    }

  }

  componentDidMount() {
    this.fetchData().then(res => {
      var sec = 0;
      var min = this.state.min;
      const fetchId = setInterval(() => {
        sec += 10;
        if (sec >= 60) {
          min += 1;
          sec = 0;
        }
        if (min >= 30) {
          this.fetchData().then(res => {
            min = this.state.min;
          });
        }

        if (this.state.index >= 61) {
          console.log("End");
          clearInterval(fetchId);
        }
      }, 10000);
    });

  }
  
  render() {
    return (
      <div>
        <div>
          <Plot
            data={[
              {
                type: 'bar',
                x: this.state.volumeChartXValues,
                y: this.state.volumeChartYValues,
                width: 0.3
              }
            ]}
            layout={{
              autosize: true,
              margin: {
                l: 65,
                r: 55,
                b: 70,
                t: 30,
                pad: 3,
              },
              paper_bgcolor: "#292e42",
              plot_bgcolor: "#292e42",
              xaxis: {
                title: "Time",
                titlefont: {
                  size: 13,
                },
                type: '',
                color: "white",
                tickmode: "array",
                tickvals: [1, 2, 3, 4, 5, 6],
                ticktext: this.state.volumeChartXLabels,
                tickfont: {
                  size: 10,
                }
              },
              yaxis: {
                title: "Volume (in thousands)",
                titlefont: {
                  size: 14,
                },
                color: "white",
                tickfont: {
                  size: 10,
                }
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shadeIntensity: 1,
                  inverseColors: false,
                  opacityFrom: 0.5,
                  opacityTo: 0,
                  stops: [0, 90, 100]
                },
              },
            }}
            // useResizeHandler={true}
            style={{ widows: "100%", height: "100%" }}
            config={{
              displayModeBar: false,
              responsive: true,
            }}
          />
        </div>

        <br />
      </div >
    )
  }
}

export default VolumeGraph;