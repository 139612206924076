import React, { useEffect } from 'react';
import styles from "./Profile.module.css";
import Heading from "../../components/Heading/Heading.js";
import { apigetProfile, apicheckUser } from '../../auth/auth';
import { useNavigate, useSearchParams } from "react-router-dom";
// import { ReactNotifications, Store } from 'react-notifications-component'
import { ReactNotifications, Store } from 'react-notifications-component'


const showMessage = (title, type) => {
  Store.addNotification({
    title: title,
    type: type,
    insert: "bottom",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true
    }
  })
}


function Profilebox(props) {


  const [searchParams, setsearchParams] = useSearchParams();
  const navigate = useNavigate();





  return (
    <>
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>K! ID</p>
        <p className={`${styles.itemContent}`}>{props.kid}</p>
      </div>
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>Name</p>
        <p className={`${styles.itemContent}`}>{props.name}</p>
      </div>
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>College</p>
        <p className={`${styles.itemContent}`}>{props.college}</p>
      </div>
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>Department</p>
        <p className={`${styles.itemContent}`}>{props.department}</p>
      </div>
      <div className={`${styles.itemRow}`}>
        <p className={`${styles.itemHeader}`}>Email</p>
        <p className={`${styles.itemContent}`}>{props.email}</p>
      </div>
    </>
  )
}

function Stocktable(props) {
  return (
    <table className={`${styles.stocktable}`}>
      <thead>
        <tr>
          <th>List of companies</th>
          <th>Number of stocks</th>
        </tr>
      </thead>
      <tr>
        <td>Aquashop</td>
        <td>{props.Aquashop}</td>
      </tr>
      <tr>
        <td>Razer Electronics</td>
        <td>{props.RazerElectronics}</td>
      </tr>
      <tr>
        <td>BV Infra</td>
        <td>{props.BVInfra}</td>
      </tr>
      <tr>
        <td>Goal Enterprise</td>
        <td>{props.GoalEnterprise}</td>
      </tr>
      <tr>
        <td>Med Pharma</td>
        <td>{props.MedPharma}</td>
      </tr>
      <tr>
        <td>Paradigm</td>
        <td>{props.Paradigm}</td>
      </tr>
      <tr>
        <td>VI Finance</td>
        <td>{props.VIFinance}</td>
      </tr>
      <tr>
        <td>ForgeTech</td>
        <td>{props.ForgeTech}</td>
      </tr>
      <tr>
        <td>Wallet</td>
        <td>{props.wallet}</td>
      </tr>
      <tr>
        <td>NetWorth</td>
        <td>{props.networth}</td> 
      </tr>
    </table>
  );
}


class Profile extends React.Component {
  constructor(props) {
    if (localStorage.getItem('token') == null) {
      window.location = '/login';
      // showMessage('Login to continue','danger')
    }
    super(props);
    this.state = {
      name: '',
      kid: '',
      email: '',
      college: '',
      department: '',
      Aquashop: 0,
      RazerElectronics: 0,
      BVInfra: 0,
      GoalEnterprise: 0,
      MedPharma: 0,
      Paradigm: 0,
      VIFinance: 0,
      ForgeTech: 0,
      wallet: 0,
      networth: 0,
    }
  }

  fetchProfile = async (config) => {
    const profile = await apigetProfile(config);
    if (profile === undefined) {
      console.log("Error");
    }
    else {
      if (profile.status >= 200 && profile.status <= 299) {
        // console.log(profile.data);
        const res = profile.data;
        if (res) {
          const userData = res.userTable;
          const stockData = res.stockTable;
          const networth = res.networth;
          console.log(userData)
          console.log(stockData)
          this.setState({
            name: userData.firstname + " " + userData.lastname,
            kid: userData.kid,
            email: userData.email,
            college: userData.college,
            department: userData.dept,
            Aquashop: stockData.Aquashop,
            RazerElectronics: stockData.RazerElectronics,
            BVInfra: stockData.BVInfra,
            GoalEnterprise: stockData.GoalEnterprise,
            MedPharma: stockData.MedPharma,
            Paradigm: stockData.Paradigm,
            VIFinance: stockData.VIFinance,
            ForgeTech: stockData.ForgeTech,
            wallet: stockData.Wallet,
            networth: networth,
          });
        }
      }
      else if (profile.status >= 400 && profile.status < 500) {
        //about to fill
      }
      else if (profile.status >= 500 && profile.status < 600) {
        console.log("Server Side Error");
      }
    }
  }

  componentDidMount() {

    const config = {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    };
    this.fetchProfile(config);
  }
  render() {
    return (
      <div className={`${styles.wrapper}`}>
        <Heading text="PROFILE" />
        <div className={`${styles.maincontainer}`}>
          <div className={`${styles.profilecontainer}`}>
            <Profilebox name={this.state.name} kid={this.state.kid} email={this.state.email} college={this.state.college} department={this.state.department} />
          </div>
          <Stocktable Aquashop={this.state.Aquashop}
            RazerElectronics={this.state.RazerElectronics}
            BVInfra={this.state.BVInfra}
            GoalEnterprise={this.state.GoalEnterprise}
            MedPharma={this.state.MedPharma}
            Paradigm={this.state.Paradigm}
            VIFinance={this.state.VIFinance}
            ForgeTech={this.state.ForgeTech}
            wallet={this.state.wallet}
            networth={this.state.networth}
          />
        </div>
      </div>
    )
  }
}

export default Profile